import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import ProfileFilterSidebar from "../components/Sidebar/ProfileFilterSidebar";
import { Select } from "../components/Core";
import axios from "axios";
import axiosConfig from '../axiosConfig';
import network from "../../constants/Network";
import { useFilterSearch } from '../context/FilterSearchContext';
import GlobalContext from "../context/GlobalContext";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark as faBookmarkRegular } from '@fortawesome/free-regular-svg-icons';
import { faBookmark as faBookmarkSolid } from '@fortawesome/free-solid-svg-icons';
import { faHospitalAlt } from '@fortawesome/free-solid-svg-icons';
import { faUserMd } from '@fortawesome/free-solid-svg-icons';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { display, justifyContent } from "styled-system";

const defaultCountries = [
    { value: "sp", label: "Singapore" },
    { value: "bd", label: "Bangladesh" },
    { value: "usa", label: "United States of America" },
    { value: "uae", label: "United Arab Emirates" },
    { value: "pk", label: "Pakistan" },
];

const ProfileListingGrid = () => {

    // const { hrFilterData = {} } = useFilterSearch() || {};

    const hrFilterData = {};

    const [showMore, setShowMore] = useState([]);

    const toggleShowMore = (id) => {
        if (showMore.includes(id)) {
            setShowMore(showMore.filter(i => i !== id));
        } else {
            setShowMore([...showMore, id]);
        }
    };

    const imageBaseUrl = `${network.serverip}/images/`;

    const [search, setSearch] = useState('');
    const [count, setCount] = useState(10);
    const [totalProfileCount, setTotalProfileCount] = useState(0);
    const [resultsFor, setResultsFor] = useState('');
    const isMountedRef = useRef(false);
    const gContext = useContext(GlobalContext);
    const { user } = useContext(GlobalContext);

    const [hrPreferences, setHrPreferences] = useState({});
    const [profiles, setProfiles] = useState([]);
    const [sortedRecommendedProfiles, setSortedRecommendedProfiles] = useState([]);

    const [userId, setUserId] = useState('');

    useEffect(() => {
        if (user && user.id) {
            setUserId(user.id)
        }
    }, [user]);

    useEffect(() => {

        if (!userId) {
            console.log('User id is not available to fetch hr preferences');
            return;
        }

        fetchHrPreference(userId);
    }, [userId]);

    const handleSearch = async (event) => {
        console.log("1. handle search fetch rec grid");
        event.preventDefault();
        setCount(10);
        setShowMore([]);
        await fetchProfiles([], search, 10);
    };

    const handleLoadMore = async () => {
        try {
            console.log("2. handle loadmore fetch rec grid");
            const newCount = count + 10;
            setCount(newCount);
            await fetchProfiles([], search, newCount);
        } catch (error) {
            console.error('Error loading more profiles:', error);
        }
    };

    const fetchHrPreference = async (userId) => {
        try {
            const response = await axiosConfig.get(`/hr_profile_preference_web/${userId}`);

            if (response.status !== 200) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            if (response.data.success && isMountedRef.current) {
                const preferenceData = response.data.data || [];

                setHrPreferences(preferenceData);

                console.log('This is the hr preferences', preferenceData);
            }
        } catch (error) {
            console.log('Error fetching hr preferences', error);
        }
    };

    const fetchProfiles = async (hrFilterData, search, count) => {
        try {

            if (!userId) {
                console.log('No user found for profiles fetching');
                return
            }

            const response = await axios.post(`${network.serverip}/api/profilelist-forweb`, { hrFilterData, search, count, userId });
            if (response.data.success && isMountedRef.current) {
                setProfiles(response.data.data);
                setTotalProfileCount(response.data.total);
                setResultsFor(search);
                if (response.data.data.length === 0) {
                    setSortedRecommendedProfiles([]);
                }
            } else if (!response.data.success) {
                if (isMountedRef.current) {
                    setProfiles([]);
                    setSortedRecommendedProfiles([]);
                    setTotalProfileCount(0);
                    setResultsFor(search);
                }
                console.error('Failed to fetch profiles');
            }
        } catch (error) {
            if (isMountedRef.current) {
                setProfiles([]);
                setSortedRecommendedProfiles([]);
                setTotalProfileCount(0);
                setResultsFor(search);
            }
            console.error('Error fetching profiles:', error);
        }
    };

    useEffect(() => {

        if (!hrPreferences || profiles.length === 0) {
            console.log("Waiting for hr preferences or profiles...");
            return;
        }

        const jobQualificationTitles = hrPreferences.qualifications?.map(q => q.qualification_title) || [];
        const jobSkillTitles = hrPreferences.jobSkills?.map(s => s.skill_title) || [];
        const experienceInMonths = hrPreferences.experienceInMonths || [];

        let sortedProfiles = profiles.map((profile) => {
            let recommendationValue = profile.recommendationValue || 0;

            const matchingQualifications = profile.qualifications.filter(qualification => jobQualificationTitles.includes(qualification.qualification_title));
            if (matchingQualifications.length > 0) {
                recommendationValue += matchingQualifications.length;
            }

            const matchingSkills = profile.skills.filter(skill => jobSkillTitles.includes(skill.skill_title));
            console.log('this is the matching skills:', matchingSkills);

            if (matchingSkills.length > 0) {
                recommendationValue += matchingSkills.length;
            }

            if (typeof profile.totalExperienceInMonths === 'number' && Array.isArray(experienceInMonths)) {
                const isExperienceMatch = experienceInMonths.some(experience =>
                    typeof experience === 'number' &&
                    (experience - 12) < profile.totalExperienceInMonths &&
                    profile.totalExperienceInMonths <= experience
                );

                if (isExperienceMatch) {
                    recommendationValue += 1;
                }
            }
            return { ...profile, recommendationValue };
        });

        sortedProfiles.sort((a, b) => {
            if (b.recommendationValue !== a.recommendationValue) {
                return b.recommendationValue - a.recommendationValue;
            }
            return new Date(b.created_at) - new Date(a.created_at);
        });

        setSortedRecommendedProfiles(sortedProfiles);
        console.log('Sorted profiles:', sortedProfiles);

    }, [hrPreferences, profiles]);

    // const handleProfileSaveOrUnsave = async (profileId) => {
    //     try {

    //         if (!userId) {
    //             gContext.toggleSignInModal();

    //             return
    //         }

    //         if (userId && profileId) {
    //             const response = await axiosConfig.post(`/saveOrUnsaveProfile-web`, { userId, profileId });

    //             if (response.data.success && isMountedRef.current) {

    //                 setProfiles((prevState) =>
    //                     prevState.map((profile) =>
    //                         profile.id === profileId
    //                             ? { ...profile, isSaved: profile.isSaved === 1 ? 0 : 1 }
    //                             : profile
    //                     )
    //                 );

    //                 console.log('Profile saved / unsaved');
    //             } else {
    //                 if (isMountedRef.current) {
    //                     console.log('Failed to save / unsave profile');
    //                 }
    //                 console.error('Failed to save / unsave profile:', response.data.message);
    //             }
    //         } else {
    //             console.error('userId or profileId is missing');
    //         }
    //     } catch (error) {
    //         console.error('Failed to save/ unsave profile:', error);
    //     }
    // };

    useEffect(() => {
        isMountedRef.current = true;

        return () => {
            isMountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            console.log("3. fetch rec list");
            await fetchProfiles([], '', 10);
        };

        fetchData();
    }, [userId]);

    const styles = ProfileListingGridStyling();

    return (
        <>
            <PageWrapper>
                <div className="bg-default-1 pt-32 pt-lg-28 pb-13 pb-lg-25">
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-12 col-xl-3 col-lg-4">
                                <ProfileFilterSidebar />
                            </div> */}
                            <div className="col-12">
                                {/* <!-- form --> */}
                                <form className="search-form" onSubmit={handleSearch}>
                                    <div className="filter-search-form-2 search-1-adjustment bg-white rounded-sm shadow-7 pr-6 py-6 pl-6">
                                        <div className="filter-inputs">
                                            <div className="form-group position-relative w-lg-45 w-xl-40 w-xxl-45">
                                                <input
                                                    className="form-control focus-reset pl-13"
                                                    type="text"
                                                    id="search"
                                                    value={search}
                                                    onChange={(event) => setSearch(event.target.value)}
                                                    placeholder="Search"
                                                />
                                                <span className="h-100 w-px-50 pos-abs-tl d-flex align-items-center justify-content-center font-size-6">
                                                    <i className="icon icon-zoom-2 text-primary font-weight-bold"></i>
                                                </span>
                                            </div>
                                            {/* <!-- .select-city starts --> */}
                                            {/* <div className="form-group position-relative w-lg-55 w-xl-60 w-xxl-55">
                                                <Select
                                                    options={defaultCountries}
                                                    className="pl-8 h-100 arrow-3 font-size-4 d-flex align-items-center w-100"
                                                    border={false}
                                                />
                                                <span className="h-100 w-px-50 pos-abs-tl d-flex align-items-center justify-content-center font-size-6">
                                                    <i className="icon icon-pin-3 text-primary font-weight-bold"></i>
                                                </span>
                                            </div> */}
                                            {/* <!-- ./select-city ends --> */}
                                        </div>
                                        <div className="button-block">
                                            <button
                                                className="btn btn-primary line-height-reset h-100 btn-submit w-100 text-uppercase"
                                                type="submit"
                                            >
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <div className="pt-12 ml-lg-0 ml-md-15">
                                    <div className="d-flex align-items-center justify-content-between mb-6">
                                        {resultsFor ? (
                                            <h5 className="font-size-4 font-weight-normal text-gray">
                                                <span className="heading-default-color">{totalProfileCount}</span>
                                                {totalProfileCount === 1
                                                    ? ` result for `
                                                    : ` results for `}
                                                <span className="heading-default-color">{resultsFor}</span>
                                            </h5>
                                        ) : (
                                            <h5 className="font-size-4 font-weight-normal text-gray">
                                                <span className="heading-default-color">{totalProfileCount}</span>
                                                {" "} {"Profile(s) found"} {" "}
                                            </h5>
                                        )
                                        }
                                        <div className="d-flex align-items-center result-view-type">
                                            <Link
                                                to="/recommended-profilelisting"
                                                className="heading-default-color pl-5 font-size-6 hover-text-hitgray"
                                            >
                                                <i className="fa fa-list-ul"></i>
                                            </Link>
                                            <Link
                                                to="/recommended-profilelisting-grid"
                                                className="heading-default-color pl-5 font-size-6 hover-text-hitgray active"
                                            >
                                                <i className="fa fa-th-large"></i>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="pt-6">
                                        <div className="row justify-content-center">
                                            {sortedRecommendedProfiles.map((profile, index) => (
                                                <div className="col-12 col-lg-6 col-xl-4" key={index}>
                                                    <div className="bg-white px-8 pt-9 pb-7 rounded-4 mb-9 feature-cardOne-adjustments">
                                                        <div className="d-block mb-7">
                                                            <div style={styles.imageContainer}>
                                                                <button
                                                                    onClick={() => gContext.toggleImageViewModal(profile?.image)}
                                                                    style={styles.imageViewButton}
                                                                >
                                                                    <img
                                                                        src={profile && profile.image ? imageBaseUrl + profile.image : imageBaseUrl + "profile_default_photo.png"}
                                                                        alt=""
                                                                        style={styles.profileImage}
                                                                    />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-column align-items-center">
                                                            <h3 className="font-size-6 heading-default-color">
                                                                <Link to={`/profile-details-view/?id=${profile.id}`}
                                                                    className="font-size-6 heading-default-color d-block mb-0">
                                                                    {profile && profile.name ? profile.name : ''}
                                                                </Link>
                                                            </h3>
                                                            <div className="mt-n4">
                                                                <p className="font-size-3 font-weight-bold text-black-2 mb-4">
                                                                    <span className="mr-2">
                                                                        <FontAwesomeIcon icon={faUserMd} className="text-success" />
                                                                    </span>
                                                                    {profile && profile.role ? profile.role : ''}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <ul className="list-unstyled mb-1 card-tag-list" style={{ display: 'flex', justifyContent: 'space-between', padding: 0 }}>
                                                            {/* <li style={styles.jobTypeBox}>
                                                                <div
                                                                    className="bg-regent-opacity-15 text-orange font-size-3 rounded-3"
                                                                >
                                                                    <i className="fa fa-briefcase mr-2 font-weight-bold"></i>{" "}
                                                                    {job && job.job_type && job.job_type.title ? job.job_type.title : ''}
                                                                </div>
                                                            </li> */}
                                                            {profile && (profile.city || profile.state) && (
                                                                <li style={styles.locationBox}>
                                                                    <div
                                                                        className="bg-regent-opacity-15 text-denim font-size-3 rounded-3"
                                                                    >
                                                                        <i className="icon icon-pin-3 mr-2 font-weight-bold"></i>{" "}
                                                                        {profile.city || ''}{profile.city && profile.state ? ', ' : ''}{profile.state || ''}
                                                                    </div>
                                                                </li>
                                                            )}

                                                            {profile?.created_at && (
                                                                <li style={styles.createdDateBox}>
                                                                    <div
                                                                        className="bg-regent-opacity-15 text-eastern font-size-3 rounded-3"
                                                                    >
                                                                        {new Date(profile.created_at).toLocaleDateString('default', {
                                                                            day: '2-digit',
                                                                            month: 'short',
                                                                            year: 'numeric'
                                                                        }).replace(/ /g, '-')}
                                                                    </div>
                                                                </li>
                                                            )}
                                                        </ul>
                                                        <div className="mb-2" style={{ textAlign: 'justify' }}>
                                                            {profile && profile.about_me ? (
                                                                <p className="font-size-4 text-black-2" >
                                                                    {((showMore.includes(profile.id)) || profile.about_me.length <= 150)
                                                                        ? `${profile.about_me} `
                                                                        : `${profile.about_me.slice(0, 150)}... `}
                                                                    {profile.about_me.length > 150 && (
                                                                        // <span onClick={() => toggleShowMore(job.id)} style={styles.showMoreButton}>
                                                                        //     {showMore.includes(job.id) ? 'Show less' : 'Show more'}
                                                                        // </span>
                                                                        <Link to={`/profile-details-view/?id=${profile.id}`} style={styles.showMoreButton}>
                                                                            {'Show more'}
                                                                        </Link>
                                                                    )}
                                                                </p>
                                                            ) : (
                                                                null
                                                            )}
                                                        </div>
                                                        <div className="w-100 mb-2">
                                                            {/* {job?.posted_by?.name && (
                                                                <p className="font-size-3 text-gray mb-0">
                                                                    {`Posted by: ${job.posted_by.name}`}
                                                                </p>
                                                            )} */}
                                                        </div>
                                                        <div className="w-100 d-flex justify-content-end">
                                                            {/* {job?.isApplied === 1 ? (
                                                                <button className="btn btn-gray text-uppercase btn-medium rounded-3" disabled>
                                                                    Applied
                                                                </button>
                                                            ) : (
                                                                <Link
                                                                    to={`/job-apply/?id=${job?.id}`}
                                                                    className="btn btn-green text-uppercase btn-medium rounded-3"
                                                                >
                                                                    Apply
                                                                </Link>
                                                            )} */}
                                                            {/* {job && job.isSaved && job.isSaved === 1 ? (
                                                                <button onClick={() => handleJobSaveOrUnsave(job?.id)}
                                                                    className="btn btn-outline-mercury text-uppercase h-px-48 rounded-3 mb-5 px-5">
                                                                    <i className="fas fa-bookmark font-weight-bold mr-4 font-size-4 text-success"></i>{" "}
                                                                    <span className="text-success">Saved</span>
                                                                </button>
                                                            ) : (
                                                                <button onClick={() => handleJobSaveOrUnsave(job?.id)}
                                                                    className="btn btn-outline-mercury text-black-2 text-uppercase h-px-48 rounded-3 mb-5 px-5">
                                                                    <i className="icon icon-bookmark-2 font-weight-bold mr-4 font-size-4"></i>{" "}
                                                                    Save job
                                                                </button>
                                                            )} */}
                                                            <button
                                                                className="btn btn-success h-px-48 rounded-3 mb-5 px-5">
                                                                <FontAwesomeIcon icon={faComments} className="font-weight-bold mr-4 font-size-4" />{" "}
                                                                Chat
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="text-center pt-5 pt-lg-13">
                                        {profiles.length > 0 && totalProfileCount > profiles.length ? (
                                            <button onClick={handleLoadMore}
                                                className="btn btn-green text-uppercase btn-medium rounded-3"
                                            >
                                                <span>
                                                    Load More <i className="fas fa-arrow-down ml-2"></i>
                                                </span>
                                            </button>
                                        ) : null}

                                        {profiles.length === 0 && (
                                            <span className="heading-default-color font-weight-bold font-size-3">
                                                No profiles found
                                            </span>
                                        )}
                                    </div>
                                </div>
                                {/* <!-- form end --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </PageWrapper>
        </>
    );
};
export default ProfileListingGrid;

function ProfileListingGridStyling() {
    const styles = {
        showMoreButton: {
            display: 'inline-block',
            background: 'none',
            border: 'none',
            color: 'blue',
            cursor: 'pointer',
            textDecoration: 'underline',
        },
        imageContainer: {
            width: '100%',
            aspectRatio: '4 / 3',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#f5f5f5',
        },
        profileImage: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        // loadMoreButton: {
        //     border: 'none',
        //     background: 'none',
        // },
        jobTypeBox: {
            backgroundColor: '#f0f0f0',
            padding: '5px',
            borderRadius: '5px',
            margin: '5px',
        },
        locationBox: {
            backgroundColor: '#f0f0f0',
            padding: '5px',
            borderRadius: '5px',
            margin: '5px',
        },
        salaryBox: {
            backgroundColor: '#f0f0f0',
            padding: '5px',
            borderRadius: '5px',
            margin: '5px',
        },
        createdDateBox: {
            backgroundColor: '#f0f0f0',
            padding: '5px',
            borderRadius: '5px',
            margin: '5px',
            display: 'flex',
            justifyContent: 'center',
        },
        imageViewButton: {
            outline: 'none',
            border: 'none',
            background: 'none',
            width: '100%',
            height: '100%',
            padding: '0',
        },

    };

    return styles;
}
